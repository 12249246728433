<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('BUS')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- photo input -->
          <single-file-upload
              v-if="id"
              @change="(value) => {form.photo = value[0];}" selection-mode="single"
              :label="$t('BUS_PHOTO_LABEL') | capitalize"
              max-m-b="50"
              :current-file="form.photo"
              :valid-extensions="['jpg','png','bmp']"
              name="photo"
              :id="id"
              :image="true"></single-file-upload>
          <!-- photo input -->

          <div class="w-100">
            <!-- floatNumber text input -->
            <b-form-group
                id="float-number-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_FLOAT_NUMBER_LABEL') | capitalize"
                label-for="floatNumber-input"
                :state="state('floatNumber')">
              <b-form-input class="w-100 ml-1" id="float-number-input" type="text"
                            v-model="$v.form.floatNumber.$model"
                            :state="state('floatNumber')"
                            trim lazy></b-form-input>

              <b-form-invalid-feedback id="float-number-input-feedback">
                <div v-for="error in errors('floatNumber')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_FLOAT_NUMBER_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- floatNumber text input -->

            <!-- series text input -->
            <b-form-group
                id="series-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_SERIES_LABEL') | capitalize"
                label-for="series-input"
                :state="state('series')">
              <b-form-input class="w-100 ml-1" id="series-input" type="text"
                            v-model="$v.form.series.$model"
                            :state="state('series')"
                            trim lazy></b-form-input>

              <b-form-invalid-feedback id="series-input-feedback">
                <div v-for="error in errors('series')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_SERIES_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- series text input -->

            <!-- plates text input -->
            <b-form-group
                id="plates-form-group"
                class="d-inline-block w-33"
                :label="$t('BUS_PLATES_LABEL') | capitalize"
                label-for="plates-input"
                :state="state('plates')">
              <b-form-input class="w-100 ml-1" id="plates-input" type="text"
                            v-model="$v.form.plates.$model"
                            :state="state('plates')"
                            trim lazy></b-form-input>

              <b-form-invalid-feedback id="plates-input-feedback">
                <div v-for="error in errors('plates')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_PLATES_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- plates text input -->
          </div>

          <div class="w-100">
            <!-- economic text input -->
            <b-form-group
                id="economic-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_ECONOMIC_LABEL') | capitalize"
                label-for="economic-input"
                :state="state('economic')">
              <b-form-input class="w-100 ml-1" id="economic-input" type="text"
                            v-model="$v.form.economic.$model"
                            :state="state('economic')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="economic-input-feedback">
                <div v-for="error in errors('economic')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_ECONOMIC_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- economic text input -->

            <!-- model text input -->
            <b-form-group
                id="model-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_MODEL_LABEL') | capitalize"
                label-for="model-input"
                :state="state('model')">
              <b-form-input class="w-100 ml-1" id="model-input" type="text"
                            v-model="$v.form.model.$model"
                            :state="state('model')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="model-input-feedback">
                <div v-for="error in errors('model')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_MODEL_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- model text input -->

            <!-- poliza text input -->
            <b-form-group
                id="poliza-form-group"
                class="d-inline-block w-33"
                :label="$t('BUS_POLIZA_LABEL') | capitalize"
                label-for="poliza-input"
                :state="state('poliza')">
              <b-form-input class="w-100 ml-1" id="poliza-input" type="text"
                            v-model="$v.form.poliza.$model"
                            :state="state('poliza')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="poliza-input-feedback">
                <div v-for="error in errors('poliza')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_POLIZA_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- poliza text input -->
          </div>

          <div class="w-100">
            <!-- polizaImg input -->
            <single-file-upload @change="() => {form.polizaImg = value;}" selection-mode="single"
                                :label="$t('BUS_POLIZA_PHOTO_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.polizaImg"
                                :valid-extensions="['jpg','png','bmp']"
                                name="licensePhoto"
                                :id="this.form.uploadDirectory"
                                :image="true"></single-file-upload>
            <!-- polizaImg input -->
          </div>

          <div class="w-100">
            <!-- routeNo text input -->
            <b-form-group
                id="route-no-form-group"
                style="vertical-align: top !important;"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_ROUTE_NO_LABEL') | capitalize"
                label-for="routeNo-input"
                :state="state('routeNo')">
              <b-form-input class="w-100 ml-1" id="route-no-input" type="text"
                            v-model="$v.form.routeNo.$model"
                            :state="state('routeNo')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="route-no-input-feedback">
                <div v-for="error in errors('routeNo')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_ROUTE_NO_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- routeNo text input -->

            <!-- seasonMaintenance text input -->
            <b-form-group
                id="season-maintenance-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_SEASON_MAINTENANCE_LABEL') | capitalize"
                label-for="seasonMaintenance-input"
                :state="state('seasonMaintenance')">
              <b-form-textarea class="w-100 ml-1" id="season-maintenance-input" type="text"
                            v-model="$v.form.seasonMaintenance.$model"
                            :state="state('seasonMaintenance')"
                            trim></b-form-textarea>

              <b-form-invalid-feedback id="season-maintenance-input-feedback">
                <div v-for="error in errors('seasonMaintenance')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_SEASON_MAINTENANCE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- seasonMaintenance text input -->

            <!-- preventiveMaintenance text input -->
            <b-form-group
                class="d-inline-block w-33"
                id="preventive-maintenance-form-group"
                :label="$t('BUS_PREVENTIVE_MAINTENANCE_LABEL') | capitalize"
                label-for="preventiveMaintenance-input"
                :state="state('preventiveMaintenance')">
              <b-form-textarea class="w-100 ml-1" id="preventive-maintenance-input" type="text"
                            v-model="$v.form.preventiveMaintenance.$model"
                            :state="state('preventiveMaintenance')"
                            trim></b-form-textarea>

              <b-form-invalid-feedback id="preventive-maintenance-input-feedback">
                <div v-for="error in errors('preventiveMaintenance')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_PREVENTIVE_MAINTENANCE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- preventiveMaintenance text input -->
          </div>

          <div class="w-100">
            <!-- gps input -->
            <b-form-group
                id="gps-form-group"
                class="d-inline-block w-33 pr-1"
                :label="$t('BUS_GPS_LABEL').toUpperCase()"
                label-for="gps-input"
                :state="state('gps')">
              <b-checkbox v-model="$v.form.gps.$model" class="w-100 mr-1" id="gps-input"></b-checkbox>

              <b-form-invalid-feedback id="gps-input-feedback">
                <div v-for="error in errors('gps')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_GPS_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- gps input -->

            <!-- gpsNumber text input -->
            <b-form-group
                v-if="form.gps"
                class="d-inline-block w-33"
                id="gps-number-form-group"
                :label="$t('BUS_GPS_NUMBER_LABEL') | capitalize"
                label-for="gps-number-input"
                :state="state('gpsNumber')">
              <b-form-input class="w-100" id="gps-number-input" type="text"
                               v-model="$v.form.gpsNumber.$model"
                               :state="state('gpsNumber')"
                               trim></b-form-input>

              <b-form-invalid-feedback id="preventive-maintenance-input-feedback">
                <div v-for="error in errors('preventiveMaintenance')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_PREVENTIVE_MAINTENANCE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- gpsNumber text input -->

            <!-- revalidation text input -->
            <b-form-group
                class="d-inline-block w-33"
                id="revalidation-form-group"
                :label="$t('BUS_REVALIDATION_LABEL') | capitalize"
                label-for="revalidation-input"
                :state="state('revalidation')">
              <b-form-input class="w-100 ml-1" id="revalidation-input" type="text"
                            v-model="$v.form.revalidation.$model"
                            :state="state('revalidation')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="revalidation-input-feedback">
                <div v-for="error in errors('revalidation')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_REVALIDATION_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- revalidation text input -->
          </div>

          <!-- brand text input -->
          <b-form-group
              class="w-33"
              id="brand-form-group"
              :label="$t('BUS_BRAND_LABEL') | capitalize"
              label-for="brand-input"
              :state="state('brand')">
            <b-form-input class="w-100 ml-1" id="brand-input" type="text"
                          v-model="$v.form.brand.$model"
                          :state="state('brand')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="brand-input-feedback">
              <div v-for="error in errors('brand')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_BRAND_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- brand text input -->

          <!-- tuneUp text input -->
          <b-form-group
              id="tune-up-form-group"
              class="w-33"
              :label="$t('BUS_TUNE_UP_LABEL') | capitalize"
              label-for="tuneUp-input"
              :state="state('tuneUp')">
            <b-datepicker class="w-100 ml-1" id="tune-up-input" type="text"
                          v-model="$v.form.tuneUp.$model"
                          :state="state('tuneUp')"></b-datepicker>

            <b-form-invalid-feedback id="tune-up-input-feedback">
              <div v-for="error in errors('tuneUp')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_TUNE_UP_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- tuneUp text input -->

          <!-- engomado text input -->
          <b-form-group
              class="w-33"
              id="engomado-form-group"
              :label="$t('BUS_ENGOMADO_LABEL') | capitalize"
              label-for="engomado-input"
              :state="state('engomado')">
            <b-form-textarea class="w-100 ml-1" id="engomado-input" type="text"
                          v-model="$v.form.engomado.$model"
                          :state="state('engomado')"
                          trim></b-form-textarea>

            <b-form-invalid-feedback id="engomado-input-feedback">
              <div v-for="error in errors('engomado')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_ENGOMADO_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- engomado text input -->

          <div class="w-100">
            <!-- year text input -->
            <b-form-group
                class="d-inline-block w-50 pr-1"
                id="year-form-group"
                :label="$t('BUS_YEAR_LABEL') | capitalize"
                label-for="year-input"
                :state="state('year')">
              <b-form-input class="w-100 ml-1" id="year-input" type="text"
                            v-model="$v.form.year.$model"
                            :state="state('year')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="year-input-feedback">
                <div v-for="error in errors('year')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_YEAR_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- year text input -->

            <!-- type input -->
            <b-form-group
                id="type-form-group"
                class="d-inline-block w-50"
                :label="$t('BUS_TYPE_LABEL') | capitalize"
                label-for="type-input"
                :state="state('type')">
              <b-select v-model="$v.form.type.$model" class="w-100 ml-1" id="verification-input">
                <b-select-option :value="0">{{ $t('BUS_TYPE_INTERN') }}</b-select-option>
                <b-select-option :value="1">{{ $t('BUS_TYPE_EXTERN') }}</b-select-option>
              </b-select>

              <b-form-invalid-feedback id="type-input-feedback">
                <div v-for="error in errors('type')" :key="error.error">
                  {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_TYPE_LABEL')},})  | capitalize }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- type input -->
          </div>

          <div class="w-100">
            <!-- engine text input -->
            <b-form-group
                id="engine-form-group"
                style="vertical-align: top !important;"
                class="d-inline-block w-50 pr-1"
                :label="$t('BUS_ENGINE_LABEL') | capitalize"
                label-for="engine-input"
                :state="state('engine')">
              <b-form-input class="w-100 ml-1" id="engine-input" type="text"
                            v-model="$v.form.engine.$model"
                            :state="state('engine')"
                            trim></b-form-input>

              <b-form-invalid-feedback id="engine-input-feedback">
                <div v-for="error in errors('engine')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_ENGINE_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- engine text input -->

            <!-- comments text input -->
            <b-form-group
                id="comments-form-group"
                class="d-inline-block w-50"
                :label="$t('BUS_COMMENTS_LABEL') | capitalize"
                label-for="comments-input"
                :state="state('comments')">
              <b-form-textarea class="w-100 ml-1" id="comments-input" type="text"
                            v-model="$v.form.comments.$model"
                            :state="state('comments')"
                            trim></b-form-textarea>

              <b-form-invalid-feedback id="comments-input-feedback">
                <div v-for="error in errors('comments')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BUS_COMMENTS_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- comments text input -->
          </div>

          <!-- company -->
          <b-form-group
              :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('COMPANY')}) | capitalize"
              label-for="company-input"
              :state="state('company')">
            <company-single-selector
                id="company-input"
                :state="state('company')"
                :errors="errors('company')"
                v-model="$v.form.company.$model">
            </company-single-selector>
          </b-form-group>
          <!-- company -->

          <h3>{{$t('BUS_PHOTOS_TITLE') | capitalize}}</h3>
          <hr />

          <div class="w-100">
            <!-- left input -->
            <single-file-upload class="d-inline-block w-33 pr-1" @change="(value) => {form.left = value[0];}" selection-mode="single"
                                :label="$t('BUS_LEFT_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.left"
                                :valid-extensions="['jpg','png','bmp']"
                                name="BUS_LEFT_LABEL"
                                :id="this.id"
                                :image="true"></single-file-upload>
            <!-- left input -->

            <!-- right input -->
            <single-file-upload class="d-inline-block w-33 pr-1" @change="(value) => {form.right = value[0];}" selection-mode="single"
                                :label="$t('BUS_RIGHT_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.right"
                                :valid-extensions="['jpg','png','bmp']"
                                name="BUS_RIGHT_LABEL"
                                :id="this.id"
                                :image="true"></single-file-upload>
            <!-- right input -->

            <!-- back input -->
            <single-file-upload class="d-inline-block w-33" @change="(value) => {form.back = value[0];}" selection-mode="single"
                                :label="$t('BUS_BACK_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.back"
                                :valid-extensions="['jpg','png','bmp']"
                                name="BUS_BACK_LABEL"
                                :id="this.id"
                                :image="true"></single-file-upload>
            <!-- back input -->
          </div>

          <div class="w-100">
            <!-- backInternal input -->
            <single-file-upload class="d-inline-block w-33 pr-1" @change="(value) => {form.backInternal = value[0];}" selection-mode="single"
                                :label="$t('BUS_BACK_INTERNAL_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.backInternal"
                                :valid-extensions="['jpg','png','bmp']"
                                name="BUS_BACK_INTERNAL_LABEL"
                                :id="this.id"
                                :image="true"></single-file-upload>
            <!-- backInternal input -->

            <!-- frontInternal input -->
            <single-file-upload class="d-inline-block w-33 pr-1" @change="(value) => {form.frontInternal = value[0];}" selection-mode="single"
                                :label="$t('BUS_FRONT_INTERNAL_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.frontInternal"
                                :valid-extensions="['jpg','png','bmp']"
                                name="BUS_FRONT_INTERNAL_LABEL"
                                :id="this.id"
                                :image="true"></single-file-upload>
            <!-- frontInternal input -->

            <!-- batteries input -->
            <single-file-upload class="d-inline-block w-33" @change="(value) => {form.batteries = value[0];}" selection-mode="single"
                                :label="$t('BUS_BATTERIES_LABEL') | capitalize"
                                max-m-b="50"
                                :current-file="form.batteries"
                                :valid-extensions="['jpg','png','bmp']"
                                name="BUS_BATTERIES_LABEL"
                                :id="this.id"
                                :image="true"></single-file-upload>
            <!-- batteries input -->
          </div>

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {minLength, maxLength, required} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Bus from "@/buses";
import Constants from "@/constants";
import SingleFileUpload from "@/components/SingleFileUpload";
import CompanySingleSelector from "@/companies/CompanySingleSelector"

export default {
  name: "BusForm",
  components: {SingleFileUpload, CompanySingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      return id ? await Bus.update(id, data) : await Bus.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Bus.findById(this.id)
          .then(resp => {
            this.form.photo = resp.data.photo;
            this.form.floatNumber = resp.data.floatNumber;
            this.form.series = resp.data.series;
            this.form.plates = resp.data.plates;
            this.form.economic = resp.data.economic;
            this.form.model = resp.data.model;
            this.form.poliza = resp.data.poliza;
            this.form.polizaImg = resp.data.polizaImg;
            this.form.routeNo = resp.data.routeNo;
            this.form.seasonMaintenance = resp.data.seasonMaintenance;
            this.form.preventiveMaintenance = resp.data.preventiveMaintenance;
            this.form.gps = resp.data.gps;
            this.form.gpsNumber = resp.data.gpsNumber;
            this.form.revalidation = resp.data.revalidation;
            this.form.brand = resp.data.brand;
            this.form.tuneUp = resp.data.tuneUp;
            this.form.engomado = resp.data.engomado;
            this.form.year = resp.data.year;
            this.form.type = resp.data.type;
            this.form.engine = resp.data.engine;
            this.form.comments = resp.data.comments;
            this.form.left = resp.data.left;
            this.form.right = resp.data.right;
            this.form.back = resp.data.back;
            this.form.backInternal = resp.data.backInternal;
            this.form.frontInternal = resp.data.frontInternal;
            this.form.batteries = resp.data.batteries;
            this.form.company = resp.data._embedded && resp.data._embedded.company ? resp.data._embedded.company._links.self.href.replace('{?projection}', '') : undefined;
            this.originals.economic = resp.data.economic
            this.originals.floatNumber = resp.data.floatNumber
            this.originals.series = resp.data.series
            this.originals.plates = resp.data.plates
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {
        economic: null
      },
      form: {
        photo: null,
        floatNumber: null,
        series: null,
        plates: null,
        economic: null,
        model: null,
        poliza: null,
        polizaImg: null,
        routeNo: null,
        seasonMaintenance: null,
        preventiveMaintenance: null,
        gps: null,
        gpsNumber: null,
        revalidation: null,
        brand: null,
        tuneUp: null,
        engomado: null,
        year: null,
        type: null,
        engine: null,
        comments: null,
        left: null,
        right: null,
        back: null,
        backInternal: null,
        frontInternal: null,
        batteries: null,
        company: null
      },
      editPage: 'BUS_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      photo: {},
      floatNumber: {minLength: minLength(1), maxLength: maxLength(100),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.floatNumber && this.originals.floatNumber === value) return true;

          // simulate async call, fail for all logins with even length
          return Bus.floatNumberExists(value);
        }
      },
      series: {required, minLength: minLength(1), maxLength: maxLength(100),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.series && this.originals.series === value) return true;

          // simulate async call, fail for all logins with even length
          return Bus.seriesExists(value);
        }
      },
      plates: {required, minLength: minLength(1), maxLength: maxLength(100),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.plates && this.originals.plates === value) return true;

          // simulate async call, fail for all logins with even length
          return Bus.platesExists(value);
        }
      },
      economic: {
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.economic && this.originals.economic === value) return true;

          // simulate async call, fail for all logins with even length
          return Bus.economicExists(value);
        }, required, minLength: minLength(1), maxLength: maxLength(100)
      },
      model: {required, minLength: minLength(1), maxLength: maxLength(100)},
      poliza: {minLength: minLength(1), maxLength: maxLength(100)},
      polizaImg: {},
      routeNo: {minLength: minLength(1), maxLength: maxLength(100)},
      seasonMaintenance: {minLength: minLength(1), maxLength: maxLength(100)},
      preventiveMaintenance: {minLength: minLength(1), maxLength: maxLength(100)},
      gps: {},
      gpsNumber: {},
      revalidation: {minLength: minLength(1), maxLength: maxLength(100)},
      brand: {minLength: minLength(1), maxLength: maxLength(100)},
      tuneUp: {minLength: minLength(1), maxLength: maxLength(100)},
      engomado: {minLength: minLength(1), maxLength: maxLength(100)},
      year: {minLength: minLength(1), maxLength: maxLength(100)},
      type: {},
      engine: {minLength: minLength(1), maxLength: maxLength(100)},
      comments: {minLength: minLength(1), maxLength: maxLength(100)},
      left: {},
      right: {},
      back: {},
      backInternal: {},
      frontInternal: {},
      batteries: {},
      company: {}
    }
  }
}
</script>

<style scoped>

</style>
